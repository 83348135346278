.pellet {
    margin-top: 3rem;
    position: relative;

    &--icon {

        &__icon {
            padding: 2rem;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-33%,-33%);

            i {
                display: inline-block;
                font-size: 2.3rem;
                position: absolute;
                top: 50%;
                left: 55%;
                transform: translate(-50%,-50%);
            }
        }

        &__content {
            font-family: 'Bitter-Bold', serif;
            font-size: 1.6rem;
            padding: 1rem 2.5rem 1rem 4rem;
        }
    }

    &--primary {

        .pellet--icon__icon {
            background-color: lighten($primary-color, 15%);

            i {
                color: $light-color;
            }
        }

        .pellet--icon__content {
            background-color: $primary-color;
            color: $light-color;
        }
    }

    &--secondary {

        .pellet--icon__icon {
            background-color: lighten($secondary-color, 15%);

            i {
                color: $light-color;
            }
        }

        .pellet--icon__content {
            background-color: $secondary-color;
            color: $light-color;
        }
    }
}