.footer {
    background-image: none;
    background-color: $light-color-variant;
    color: $dark-color;

    &-logo {
        background-color: $primary-color;
        border-radius: 1rem;
        display: inline-block;
        padding: 1.5rem;
        width: 15rem;

        img {
            max-height: none;
        }
    }

    .social-icons li > a:hover {
        color: $secondary-color;
    }
}

.widget ul#menu-footer-services {

    li {
        display: block;
        width: 100%;
    }
}