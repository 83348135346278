.banner-section-carousel {
    height: 650px;
}
.banner-section-carousel .owl-stage-outer,
.banner-section-carousel .owl-stage,
.banner-section-carousel .owl-item,
.banner-section-carousel .owl-item > div,
.banner-section-carousel .owl-item > div > .container {
    height: 100%;
}
.banner-section-carousel .owl-item {
    padding: 0;
}
.banner-section-carousel .owl-item > div {
    background-size: cover;
    background-position: center center;
    position: relative;
    isolation: isolate;
}
.banner-section-carousel .owl-item > div::before {
    content: "";
    background: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}
.banner-section-carousel .owl-item .container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 60px;
}
.banner-section-carousel .bsc-top {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    letter-spacing: .4em;
    opacity: 0;
    padding-left: 30px;
    position: relative;
    text-transform: uppercase;
}
.banner-section-carousel .bsc-top::before {
    --size: 15px;
    content: "";
    background-color: #e41f21;
    border-radius: var(--size);
    position: absolute;
    top: 6px;
    left: 0;
    height: var(--size);
    width: var(--size);
}
.banner-section-carousel .bsc-top--2::before {
    background-color: #2d52a0;
}
.banner-section-carousel .bsc-title {
    color: #fff;
    font-size: 78px;
    font-weight: 400;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 15px;
    opacity: 0;
}  
.banner-section-carousel .bsc-title__main {
    display: block;
    font-family: "Bitter-Bold", serif;
    text-transform: uppercase;
}
.banner-section-carousel .bsc-title__main > span {
    display: inline-block;
    position: relative;
}
.banner-section-carousel .bsc-title__main > span::after {
    content: "";
    background-color: #e1ce19;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 8px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: top left;
}
.banner-section-carousel .bsc-title--2 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.banner-section-carousel .bsc-title--2 .bsc-title__main {
    font-size: 60px;
}
.banner-section-carousel .bsc-title--2 .bsc-title__sup {
    display: block;
    font-size: 35px;
    line-height: 1;
    letter-spacing: .3em;
    text-transform: uppercase;
}
.banner-section-carousel .bsc-list {
    display: flex;
    flex-flow: row wrap;
    gap: 20px 40px;
    align-items: center;
    justify-content: center;
}
.banner-section-carousel .bsc-list > li {
    color: #fff;
    font-size: 25px;
    letter-spacing: .2em;
    position: relative;
    opacity: 0;
}
.banner-section-carousel .bsc-list > li:not(:first-child)::before {
    --size: 15px;
    content: "";
    background-color: #e41f21;
    border-radius: var(--size);
    position: absolute;
    top: 6px;
    left: -30px;
    height: var(--size);
    width: var(--size);
}
.banner-section-carousel .bsc-list > li:last-child::after {
    --size: 15px;
    content: "";
    background-color: #e41f21;
    border-radius: var(--size);
    position: absolute;
    top: 6px;
    right: -30px;
    height: var(--size);
    width: var(--size);
}
.banner-section-carousel .bsc-button-wrap {
    margin-top: 15px;
    text-align: center;
}
.banner-section-carousel .bsc-button {
    background-color: #e41f21;
    color: #fff;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    opacity: 0;
    padding: 15px 25px;
    text-transform: uppercase;
    transition: .4s;
}
.banner-section-carousel .bsc-button:hover {
    background-color: #3460bb;
}
.banner-section-carousel .owl-controls .owl-nav div.owl-prev,
.banner-section-carousel .owl-controls .owl-nav div.owl-next {
    --size: 50px;
    background-color: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size);
    width: var(--size);
}
.banner-section-carousel .owl-controls .owl-nav div.owl-prev::before,
.banner-section-carousel .owl-controls .owl-nav div.owl-next::before {
    font-size: 30px;
}
.banner-section-carousel .owl-controls .owl-nav div.owl-prev, 
.banner-section-carousel .owl-controls .owl-nav div.owl-next.owl-theme .owl-controls .owl-nav div.owl-prev, 
.banner-section-carousel .owl-controls .owl-nav div.owl-next {
    top: 50%;
}
.banner-section-carousel .owl-controls .owl-nav div.owl-prev {
    left: 0;
}
.banner-section-carousel .owl-controls .owl-nav div.owl-next {
    right: 0;
}
.banner-section-carousel .owl-item.active .bsc-top { animation: cfadeInTop ease-in-out .6s forwards; }
.banner-section-carousel .owl-item.active .bsc-title { animation: cfadeInTop ease-in-out .6s .25s forwards; }
.banner-section-carousel .owl-item.active .bsc-title__main > span::after { animation: cscaleXIn ease-in-out .6s .5s forwards; }
.banner-section-carousel .owl-item.active .bsc-list > li:nth-child(1) { animation: cfadeInBottom ease-in-out .6s .75s forwards; }
.banner-section-carousel .owl-item.active .bsc-list > li:nth-child(2) { animation: cfadeInBottom ease-in-out .6s .85s forwards; }
.banner-section-carousel .owl-item.active .bsc-list > li:nth-child(3) { animation: cfadeInBottom ease-in-out .6s .95s forwards; }
.banner-section-carousel .owl-item.active .bsc-list > li:nth-child(4) { animation: cfadeInBottom ease-in-out .6s 1.05s forwards; }
.banner-section-carousel .owl-item.active .bsc-list > li:nth-child(5) { animation: cfadeInBottom ease-in-out .6s 1.15s forwards; }
.banner-section-carousel .owl-item.active .bsc-button { animation: cfadeInBottom ease-in-out .6s 1.3s forwards; }

@media screen and (max-width: 1199px) {
    .banner-section-carousel .bsc-top { font-size: 22px; }
    .banner-section-carousel .bsc-title, .banner-section-carousel .bsc-title--2 .bsc-title__main {  font-size: 55px; }
    .banner-section-carousel .bsc-title--2 .bsc-title__sup { font-size: 28px; }
    .banner-section-carousel .bsc-list > li { font-size: 20px; }
}
@media screen and (max-width: 768px) {
    .banner-section-carousel .owl-item .container { padding-top: 0; }
    .banner-section-carousel .bsc-top { text-align: center; font-size: 20px; padding-left: 0; }
    .banner-section-carousel .bsc-top::before { display: none; }
    .banner-section-carousel .bsc-title, .banner-section-carousel .bsc-title--2 { margin-top: 20px; margin-bottom: 20px; }
    .banner-section-carousel .bsc-title, .banner-section-carousel .bsc-title--2 .bsc-title__main { text-align: center; font-size: 40px; }
    .banner-section-carousel .bsc-title--2 .bsc-title__sup { text-align: center; font-size: 23px; }
    .banner-section-carousel .bsc-list > li { text-align: center; font-size: 18px; }
    .banner-section-carousel .bsc-list > li::before, .banner-section-carousel .bsc-list > li::after { display: none !important; }
}