.card {

    &--style1 {
        border: none;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        padding: 4.5rem 7rem;
        position: relative;

        @include respondMax($desktop) {
            flex-direction: column;
        }

        @include respondMax($phone) {
            padding: 2rem 3rem;
        }

        &::before, 
        &::after {
            content: "";
            position: absolute;
            height: 35%;
            width: .6rem;

            @include respondMax($phone) {
                display: none;
            }
        }

        &::before {
            top: 3.5rem;
            left: 3.5rem;
        }
        &::after {
            bottom: 3.5rem;
            right: 3.5rem;
        }

        .card__title {
            margin-right: 3rem;
            padding-right: 3rem;
            position: relative;
            width: 40%;

            @include respondMax($desktop) {
                margin-bottom: 1.5rem;
                margin-right: 0;
                padding-right: 0;
                width: 100%;
            }

            &::after {
                content: "";
                background-color: $primary-color;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: .2rem;

                @include respondMax($desktop) {
                    display: none;
                }
            }

            h2 {

                @include respondMax($laptop) {
                    font-size: 3rem;
                    letter-spacing: 1.3;
                }

                @include respondMax($phone) {
                    font-size: 2.8rem;
                    line-height: 1.5;
                }
            }
        }

        .card__content {
            flex: 0 1 60%;

            p {
                font-size: 1.6rem;
                letter-spacing: .1em;
                opacity: .85;
                text-align: justify;

                &:last-child {
                    margin-bottom: 1.5rem;
                }
            }
        }

        &.card--primary {
            background-color: $primary-color;

            &::before,
            &::after {
                background-color: rgba($light-color, .3);
            }

            .card__title {

                &::after {
                    background-color: rgba($light-color, .3);
                }

                * {
                    color: $light-color;
                }
            } 

            .card__content {

                p {
                    color: $light-color;
                }
            }
        }
    }
}