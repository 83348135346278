.breadcrumb {
    background-color: transparent;

    li {
        font-size: 1.6rem;

        &:not(:last-child) {
            font-weight: 300;
            opacity: .8;
            padding-right: 2rem;
            position: relative;
            margin-right: 1rem;

            &::after {
                content: ">";
                font-size: 1.5em;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }

        &:last-child {
            font-weight: bold;  
        }
    }
}

.checkbox-group {
    cursor: pointer;
    display: inline-block;
}
.checkbox-group input { 
    display: none; 
}
.checkbox-group input ~ .label {
    font-size: 15px;
}
.checkbox-group input:checked ~ .label {
    color: $primary-color; 
}
.checkbox-group input:checked ~ .label::before {
    background-color: $primary-color;
    border-color: $primary-color;
}
.checkbox-group .label {
    display: inline-flex;
    align-items: flex-start;
    font-weight: bold;
    position: relative;
    transition: .5s;
}  
.checkbox-group .label::before {
    content: "✓";
    background-color: #fff;
    border: 2px solid #000;
    color: #fff;
    display: inline-block;
    margin-right: 1rem;
    padding: 3px 5px;
    transition: .5s;
    line-height: 1;
}

.featured {
    color: $dark-color;
    font-size: 2rem;
    font-weight: bold;
}
.featured-title {

    .h5 {
        font-family: 'Bitter', serif;
    }

    .h6 {
        display: block;
    }

    &.ttm-textcolor-skincolor-tertiary {

        .h6 {
            color: $tertiary-color;
        }
    }
}

.highlight-text {
    background: url('/storage/webp/electrical-tools-and-kit-w200.webp') no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: center center;
}

.jobs-wrap {
    max-height: 400px;
    overflow-y: scroll;
}
.job-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 25px;
    margin-bottom: 15px;
}
.job-item .job-suptitle {
    color: $primary-color;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
}
.job-item .job-title {
    color: $dark-color;
    display: block;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
}
.job-item .job-infos {
    background-color: $light-color-variant;
    border-radius: 10px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 15px;
    padding: 5px 15px;
}
.job-item .job-infos-item {
    display: flex;
    align-items: center;
}
.job-item .job-infos-item:not(:last-child) {
    margin-right: 10px;
}
.job-item .job-infos-item .icon {
    color: $primary-color;
    font-size: 16px;
    margin-right: 5px;
}
.job-item .job-infos-item .text {
    font-size: 15px;
}

.list-icon-h {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 0;
    padding-left: 0 !important;
    list-style: none !important;
}
.list-icon-h li a {
    border: 2px solid $secondary-color;
    color: $secondary-color;
    display: flex;
    align-items: center;    
    justify-content: center;
    font-size: 20px;
    height: 40px;
    width: 40px;
}
.list-icon-h li a:hover {
    background-color: $secondary-color;
    color: #fff;
}

.site-header-menu-inner.fixed-header {
    top: 50px;
}

/**
 * >>> CONTACT STYLE <<<
 */

// Form notifications
.notif {
    color: $color-danger;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    padding-left: 15px;
    position: relative;

    &::before {
        content: "";
        border-radius: 10px;
        background-color: $color-danger;
        line-height: 0;
        position: absolute;
        left: 0;
        top: 8px;
        height: 6px;
        width: 6px;
    }

    &::after {
        content: "";
        background-color: $color-danger;
        border-radius: 4px;
        position: absolute;
        top: 9px;
        left: 1px;
        height: 4px;
        width: 4px;
        animation: grow-transp 1s ease-in-out infinite forwards;
    }
}


// Form notification success
.notif-form-success {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        flex-shrink: 0;
        margin-right: 15px;
        height: 50px;
        width: 50px;
        
        & > * { 
            stroke-dasharray: 1200;
            stroke-dashoffset: 1200;
            stroke: $color-success; 
        }
        & > *:first-child { animation: stroke-appear-1 .75s .5s cubic-bezier(0.33, 1, 0.68, 1) forwards; }
        & > *:last-child { animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards; }
    }

    span {
        color: $color-success;
        font-size: 18px;
        animation: fade-in .5s ease-in-out forwards;
    }
}


// Animations
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes grow-transp {
    from { opacity: .8; transform: scale(1); }
    to { opacity: 0; transform: scale(4); }
}
@keyframes stroke-appear-1 {
    from { 
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
    }
    to {
        stroke-dasharray: 1200;
        stroke-dashoffset: 0;
    }
}

.owl-theme .owl-controls .owl-nav {
    margin-top: 2rem;

    div.owl-prev,
    div.owl-next {
        background-color: $primary-color;
        top: 180px;

        &:hover {
            background-color: lighten($primary-color, 15%);
        }
    }
}

.post-featured {
    text-align: center; 
}

.ttm_single_image-wrapper {
    position: relative;
}
.ttm_single_image-wrapper .single-image-text {
    background-color: $primary-color;
    color: #fff;
    font-family: 'Bitter-Bold', serif;
    font-size: 18px;
    outline: 10px solid #fff;
    padding: 30px 20px 25px;
    position: absolute;
    bottom: -50px;
    left: 42px;
    max-width: 80%;
}
.ttm_single_image-wrapper .single-image-text .sit-icon {
    background-color: $secondary-color;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    position: absolute;
    left: 20px;
    top: -10px;
    transform: translateY(-50%);
    width: 45px;
    height: 60px;
}
.ttm_single_image-wrapper--2 {
    margin-bottom: 70px;
}

.ttm-btn.ttm-btn-color-white {
    color: $primary-color;
    border-color: #fff;
    background: #fff;
}
.ttm-btn.ttm-btn-color-white:hover {
    background: transparent;
    color: #fff;
}
.ttm-btn.ttm-btn-color-white.ttm-btn-style-border {
    color: #fff;
    border-color: #fff;
    background: transparent;
}
.ttm-btn.ttm-btn-style-full {
    background-color: $primary-color;
    color: #fff;
    &:hover { background-color: $dark-color; }
}

.ttm-bgcolor-darkgrey .featured-title h6,
.ttm-icon_element-color-skincolor-tertiary {
    color: $tertiary-color;
}

.tm-list.tm-list-style-icon {
    
    li {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        i {
            color: $secondary-color;
            margin-top: .4rem;
            margin-right: 1rem;
        }

        span {
            line-height: 1.6;
        }
    }
}

.ttm-bgcolor-skincolor-light-variant-2 {

    .ttm-bg-layer {
        background-color: $light-color-variant-2;
    }
}

.ttm-box-post-date {
    font-size: 1.5rem;
    font-weight: normal;
    padding: .75rem 1.25rem;
    height: auto;
    width: auto;
}

.ttm-recent-post-list-li {
    display: flex;

    .left {
        flex-shrink: 0;
        margin-right: 1rem;
        width: 35%;
        min-width: 6rem;

        img {
            height: auto;
            width: auto;
        }
    }

    .right {

        a {
            font-size: 1.4rem;
            line-height: 1;
        }
    }
}

.tp-caption.skin-flat-button, 
.skin-flat-button {
    background-color: $secondary-color;
}

.pt-300 { padding-top: 300px !important; }