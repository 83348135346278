h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Bitter-Bold', serif;
    font-weight: 600;
    color: #2d52a0;
}

.h2 {
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 1.3;
}

.para-feat {
    font-size: 17px;
    font-weight: bold;
}

.section-title  {

    .title-header {

        &::after {
            background-color: $secondary-color;
        }

        .sup {
            display: block;
            font-weight: 600;
        }
    }
   
    h2.title {
        font-family: 'Bitter-Bold', serif;
        font-weight: bold;

        &--sm {
            font-size: 2.8rem;
        line-height: 1.3;
        }

        a,
        a:hover {
            color: inherit;
        }
    }
}

.text-primary { color: $primary-color !important; }