@mixin clearfix {
    &::after {
        content: "";
        display: table;
        float: none;
        clear: both;
    }
}

@mixin dash-text {
	padding-left: 2.5rem;
	position: relative;

	&::before {
		content: "";
		background-color: rgba($primary-color, .8);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		max-height: 1em * 1.1;
		width: .75rem;
		transform: skewX(20deg);
    }
    
    &-white {

        &::before {
            background-color: $light-color;
        }
    }
}



/* || RESPONSIVE
************************************************/
@mixin respondMax($breakpoint) {
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}
@mixin respondMin($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		@content;
	}
}
/************************************************/