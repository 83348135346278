.header {
    position: relative;
    z-index: 99999;

    .container {
        position: relative;

        @media (min-width: 1200px) {
            max-width: 1400px;
        }
    }

    .ttm-topbar-wrapper {
        background-color: #3460bb;

        a:not(.ttm-btn) {
            display: inline-block;
            position: relative;

            &:after {
                content: "";
                background-color: $light-color;
                position: absolute;
                bottom: 1.3rem;
                left: 0;
                height: 1px;
                width: 0;
                transition: .5s;
            }

            &:hover {
                color: $light-color;

                &::after {
                    width: 100%;
                }
            }
        }
    }

    .top-contact {

        &::after {
            background-color: $primary-color;
        }

        li {
            display: inline-block;
            padding-right: 18px;
        }
    }

    .topbar-right .social-icons {

        li:last-child {
            padding-right: 1.8rem;
            
            &::after {
                content: "";
                height: 22px;
                width: 1px;
                background-color: rgba(255,255,255,.2);
                display: block;
                position: absolute;
                right: 0;
                top: 14px;
            }
        }
    }

    .site-branding {
        background-color: $primary-color;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        height: 20rem;
        width: 22rem;
        z-index: 99;
        transition: $transition-md;

        @include respondMax(1400px) {
            border-radius: 0;
            left: 50%;
            height: 11.5rem;
            transform: translateX(-50%);
            width: 15rem;
        }

        .home-link {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 65%;

            @include respondMax(1400px) {
                height: 100%;
            }

            img {
                vertical-align: top;
                height: 90%;
                max-height: none;
            }
        }
    }

    .site-header-menu-inner.fixed-header {

        .site-branding {
            border-radius: 0;
            width: 10rem;

            @media (max-width: 1200px) {
                width: 100%;
            }

            .home-link {
                text-align: center;
                height: 90%;
            }
        }
    }
}