.cookie-consent {
    background-color: lighten($primary-color, 15%) !important;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-flow: column nowrap;
        padding: 10px 15px !important;
    }

    .cookie-consent__message {
        padding-right: 2rem;
        height: auto !important;
    }

    .cookie-consent__agree {
        background-color: darken($primary-color, 15%) !important;
        border-color: darken($primary-color, 15%) !important;
        
        &:hover {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
        }
    }

    
}