.col-bg-img-two.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
    background-image: url('/storage/accueil/technicien-bleu-serrant-la-main-client-h1000.jpg') !important;
}
.col-bg-img-three.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
    background-image: url('/storage/accueil/electric-charging-station-w886.jpg') !important;
}

.ttm-row.electric-charging-section {
    padding-top: 16rem;
    padding-bottom: 6rem;
}

.introduction-section {
    padding-bottom: 15rem;
    position: absolute;
    transform: translateY(-22%);
    width: 100%;
    z-index: 999;

    & ~ .site-main {
        padding-top: 20rem;
    }

    .logos-card {
        background-color: #fff;
        margin: 0 auto;
        max-width: 75%;

        @include respondMax($laptop) {
            max-width: 100%;
        }

        .owl-item {
            text-align: center;
        }

        img {
            max-height: 100px;
        }
    }

    @include respondMax($desktop) {
        padding-bottom: 0;
        position: relative;
        transform: translateY(-10%);
    }
}

.search-electrician-section {
    
    @include respondMax($desktop) {
        padding-top: 50px !important;
    }
}

.section-climatisation {
    margin-top: -75px;
}
.section-climatisation .sc-title-wrap {
    background-size: cover;
    background-position: center center;
    margin-bottom: 40px;
    padding-top: 140px;
    padding-bottom: 70px;
    position: relative;
    isolation: isolate;
    text-align: center;
}
.section-climatisation .sc-title-wrap::before {
    content: "";
    background-color: #112144;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
}
.section-climatisation .sc-title-wrap::after {
    content: "";
    background-color: #112144;
    position: absolute;
    opacity: .8;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}
.section-climatisation .sc-title-wrap .sc-title {
    color: #fff;
    padding-bottom: 25px;
    position: relative;
}
.section-climatisation .sc-title-wrap .sc-title::after {
    content: "";
    background-color: $secondary-color;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 4px;
    width: 100px;
    transform: translateX(-50%);
}
.section-climatisation .sc-title-wrap .sc-title-sup {
    color: $tertiary-color;
    font-size: 20px;
    margin-bottom: 0;
    text-transform: none;
}