@font-face {
    font-family: 'Bitter';
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Regular.eot');
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Regular.eot?#iefix') format('embedded-opentype'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Regular.woff2') format('woff2'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Regular.woff') format('woff'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Regular.svg#Bitter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bitter-Bold';
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Bold.eot');
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Bold.eot?#iefix') format('embedded-opentype'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Bold.woff2') format('woff2'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Bold.woff') format('woff'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Bold.svg#Bitter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bitter-Italic';
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Italic.eot');
    src: url('/themes/carrondo/fonts/Bitter/Bitter-Italic.eot?#iefix') format('embedded-opentype'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Italic.woff2') format('woff2'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Italic.woff') format('woff'),
        url('/themes/carrondo/fonts/Bitter/Bitter-Italic.svg#Bitter-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}


