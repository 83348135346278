@-webkit-keyframes growTransp {
    from  { opacity: 1; }
    to    { opacity: 0; height: 20px; width: 20px; }
}
@keyframes growTransp {
    from  { opacity: 1; }
    to    { opacity: 0; height: 20px; width: 20px; }
}
@keyframes cfadeInTop {
    from { opacity: 0; transform: translateY(-25px); }
    to { opacity: 1; transform: translateY(0); }
}
@keyframes cfadeInBottom {
    from { opacity: 0; transform: translateY(25px); }
    to { opacity: 1; transform: translateY(0); }
}
@keyframes cscaleXIn {
    from { opacity: 0; transform: scaleX(0); }
    to { opacity: 1; transform: scaleX(1); }
}