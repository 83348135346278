.form {

    // FIELDSET
    .fieldset {
        border-top: 1px solid rgba($dark-color, .15);
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    // GROUP
    &-group {
        margin: 0;
        position: relative;
        
        
        // LABELS
        &__label {
            margin-bottom: 0;
            opacity: 1;
            position: absolute;
            top: -2.7rem;
            left: 0;
            visibility: visible;
            transform: translate(0);
            transition: $transition-md;
            
            &--visible {
                top: .5rem;
            }
        }
        
        
        // INPUTS
        &__input {
            border-radius: 3px;
            display: block;
            margin-top: 3rem;
            padding: .75rem 1.5rem;
            width: 100%;
        }
        &__input {
            transition: $transition-md;
            
            /*&:invalid,
            &:valid {
                box-shadow: none;
                outline: none;
            }*/
        }
        &__input:placeholder-shown ~ &__label {
            opacity: 0;
            visibility: hidden;
            transform: translate(1.5rem, 3.35rem);
            transition: none;
        }
        
        
        // SELECTS
        /*
         * To change the style of the selects, check the _select2.scss file 
         * path: /platform/themes/securpermis/assets/scss/vendors/select2.scss
        */
        
    }

    // NOTIFICATIONS
    .notif {
        display: inline-block;
        font-size: 1.1em;
        line-height: 1.6;
        padding-left: 25px;
        position: relative;

        &::before,
        &::after {
            content: "";
            border-radius: 100px;
            position: absolute;
            left: 8px;
            top: 11px;
            height: 6px;
            width: 6px;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
        }
        &::after {
            -webkit-animation: growTransp 1s infinite backwards;
                animation: growTransp 1s infinite backwards;
        }

        
        &--success {
            color: #05b75e;
            
            &::before,
            &::after {
                background-color: #05b75e;
            }
        }

        &--danger {
            color: #ef475b;
            
            &::before,
            &::after {
                background-color: #ef475b;
            }
        }

        &--main {
            margin-bottom: 15px;
            margin-left: 1rem;
        }
    }
}